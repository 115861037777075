import React, { useState } from 'react';
import ScratchCard from "react-scratchcard";
import ScarthCardImg from '../assets/ic_product_banner.png'
import PlanImg from '../assets/resize-16951990541426435376saasasas.png'

const UnLockCard = ({ onScratchComplete }) => {
    const [scratchedText, setScratchedText] = useState(
        "Congratulations! You WON!"
    );

    const handleScratchComplete = () => {
        console.log('The card is now clear!');
        setScratchedText('Congratulations! You WON!');
        onScratchComplete();
    };

    const geht = false;
   

    const settings = {
        image: PlanImg,
        finishPercent: 30,
        brushSize: 20,
        className: 'w-3/4 md:w-2/3 lg:w-1/2 h-auto', // Add the CSS class names here
    };
    

    return (

        <div className="flex items-center justify-center w-full max-h-screen overflow-hidden">
            <div className="bg-white text-gray-800 shadow-lg relative flex flex-col w-[24rem]">
                <ScratchCard {...settings} onComplete={handleScratchComplete} className="ml-auto rounded mr-auto">
                    {geht ? (
                        <div>
                            <p>hallo</p>
                        </div>
                    ) : (
                        <div className="flex justify-center items-center h-full">
                            <img src={ScarthCardImg} alt="Product Banner" className="w-3/4 md:w-2/3 lg:w-1/2 h-auto" /> {/* Add `w-full` */}
                        </div>
                    )}
                </ScratchCard>
            </div>
        </div>
    )
}

export default UnLockCard;
