import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import UnLockCard from './UnLockCard';
import bannerImage from '../assets/ic_luckky_winner_banner.png';
import scrathImgWin from '../assets/ic_scratch_win_banner.png';
import sssImg from '../assets/saasasas.png';
import ConfettiExplosion from 'react-confetti-explosion';
import toast, { Toaster } from 'react-hot-toast';

const Scrath = () => {
    console.log("1")
    const navigate = useNavigate();
    const [isScratched, setIsScratched] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [searchParams] = useSearchParams();

    const paramValue = searchParams.get('payment');
    console.log('paramValue:', paramValue);


    const handleUnlockButtonClick = () => {

        window.location.replace(`https://astropandit.store/payment_dd.html?q=bnpvou>611'xfctjuf>iuuqt;00mvdlzxjoofss/ofumjgz/bqq`);

    };



    // Handle initial state based on payment parameter
    useEffect(() => {

        console.log("2")
        if (paramValue === 'SUCCESS') {
            console.log("3")
            setIsScratched(true);
        } else if (paramValue === 'FAILED' || paramValue === 'PENDING') {
            console.log("4")
            navigate('/');
            console.log("5")
            setTimeout(() => {
                toast.error('Your payment credentials are invalid or your payment time has run out. Please try again.', {
                    duration: 2000,
                });
            }, 0);
        }
    }, [paramValue, navigate]);



    const handleScratchComplete = () => {
        console.log("6")
        setIsScratched(true);
        setShowConfetti(true);
        setTimeout(() => {
            console.log("7")
            setShowConfetti(false);
            navigate('/');
            console.log("8")
            setTimeout(() => {
                toast.success('Your gift will arrive in 2 days!', {
                    duration: 3000, // Duration in milliseconds
                });
            }, 0);
        }, 1000);
    };

    return (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                
        <div className="bg-white text-gray-800 shadow-lg relative flex flex-col w-[24rem] h-[95vh] overflow-hidden">
            <div className="bg-bluecolor w-full p-4 h-full relative">
                    <img src={bannerImage} alt="Banner Image" className="w-full h-auto mb-4" />
                    <div className="flex justify-center py-3">
                        <img src={scrathImgWin} alt="Your Image" className="w-[58%]" />
                    </div>


                    {paramValue !== 'SUCCESS' && (

                        <div className="w-full h-auto flex flex-col items-center justify-center">
                            <div className="relative w-full h-auto flex flex-col items-center justify-center">
                                <img src={sssImg} alt="Saasasas" className="w-3/4 md:w-2/3 lg:w-1/2 h-auto" />
                                <button
                                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-btncolor text-whitwcolor py-2 px-4 rounded"
                                    onClick={handleUnlockButtonClick}
                                >
                                    Unlock Card
                                </button>
                            </div>
                            <h1 className='text-3xl font-bold text-[#fff] py-4'>₹ 500</h1>
                        </div>
                    )}


                    {paramValue === 'SUCCESS' && (
                        <div className="w-full flex flex-col items-center">
                            <UnLockCard onScratchComplete={handleScratchComplete} />
                            {isScratched && (
                                <>
                                    {showConfetti && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                zIndex: 10, // Optional: Adjust the z-index if you need to bring the confetti to the front
                                            }}
                                        >
                                            <ConfettiExplosion
                                                duration={2000} // Confetti explosion duration in milliseconds
                                                particleCount={500} // Adjust the number of particles as desired
                                            />
                                        </div>

                                    )}
                                </>
                            )}
                            {paramValue === 'SUCCESS' && (
                                <p className="text-[#fff] text-center mt-3 ">
                                    *  Your payment has been successfully completed. Please scratch the card and receive your gift.
                                </p>
                            )}
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
};

export default Scrath