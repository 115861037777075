
    import { initializeApp } from 'firebase/app';
    import { getFirestore } from 'firebase/firestore';

    const firebaseConfig = {
    apiKey: "AIzaSyCRy7oPnvQVt07U5iNwSKBHGCCab36jruI",
    authDomain: "luckywinner-29157.firebaseapp.com",
    projectId: "luckywinner-29157",
    storageBucket: "luckywinner-29157.appspot.com",
    messagingSenderId: "371362546182",
    appId: "1:371362546182:web:172f0f10856ebe150bb987",
    measurementId: "G-V6F1KZ0NMK"
    };

    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);

    export { db };