import React, { useState } from 'react';
import FormData from './FormData';
import { Link, Outlet } from "react-router-dom";
import "../Componets/media.css"



export const Enrollennow = () => {
    const [showFormData, setShowFormData] = useState(false);

    const handleEnrollClick = () => {
        setShowFormData(true);
    };

    return (
        <>
               <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                
                <div className="bg-white text-gray-800 shadow-lg relative flex flex-col w-[24rem] h-[95vh] overflow-hidden">
                    <div className="bg-bluecolor w-full p-4 h-full relative">
                        <div className="relative text-center">
                            <img
                                src="ic_luckky_winner_banner.png"
                                alt="Banner Image"
                                className="w-full h-auto max-w-full"
                                style={{ maxHeight: '300px' }} // Add a fixed height for the image
                            />
                        </div>

                        <div>
                            <img
                                src="ic_product_allbanner.png"
                                alt="Product Banner"
                                className="w-full h-auto max-w-full "
                                style={{ maxHeight: '400px' }} // Add a fixed height for the image
                            />
                        </div>

                        <div className="mt-4">
                            <Link to="/formdata">
                                <button
                                    className="bg-blubluecolor text-whitwcolor font-bold tracking-wider text-lg py-2 px-4 w-full rounded-lg"
                                    onClick={handleEnrollClick}
                                >
                                    Enroll Now
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <Outlet />
        </>
    );
}
