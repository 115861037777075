import React, { useEffect, useState } from 'react';

const Data = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch data from the API when the component mounts
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://hapi.hindustanrides.in/api/v1/all_luckywinner');
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const result = await response.json();
                console.log("result", result);
                setData(result.data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    // Render the data table
    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4 text-center text-[#fff] bg-[green]">User Data</h1>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}
            {!loading && !error && (
                <div className="overflow-x-auto">
                    <table className="w-full text-[#fff] border-collapse">
                        <thead>
                            <tr>
                                <th className="px-4 py-2 border-b">ID</th>
                                <th className="px-4 py-2 border-b">Name</th>
                                <th className="px-4 py-2 border-b">Email</th>
                                <th className="px-4 py-2 border-b">Phone</th>
                                <th className="px-4 py-2 border-b">Address</th>
                                <th className="px-4 py-2 border-b">Pincode</th>
                                <th className="px-4 py-2 border-b">State</th>
                                <th className="px-4 py-2 border-b">Post Office</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item,i) => (
                                <tr key={item.id} className="border-b">
                                    <td className="px-4 py-2">{i+1}</td>
                                    <td className="px-4 py-2">{item.fname}</td>
                                    <td className="px-4 py-2">{item.email}</td>
                                    <td className="px-4 py-2">{item.phone}</td>
                                    <td className="px-4 py-2">{item.address}</td>
                                    <td className="px-4 py-2">{item.pincode}</td>
                                    <td className="px-4 py-2">{item.state}</td>
                                    <td className="px-4 py-2">{item.selectedPostOffice}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default Data;
