

import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Scrath } from './Scrath';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase';
import bannerImage from '../assets/ic_luckky_winner_banner.png';

const initialForm = {
    fname: '',
    email: '',
    phone: '',
    address: '',
    state: '',
    pincode: '',
    city: '',
    selectedPostOffice: '',
};

const FormData = () => {

    const [searchParams] = useSearchParams();

    const paramValue = searchParams.get('payment');
    console.log('paramValue:', paramValue);


    const navigate = useNavigate();
    const [form, setForm] = useState(initialForm);
    const [record, setRecord] = useState([]);
    const [postOfficeNames, setPostOfficeNames] = useState([]);
    const [searchErrorMessage, setSearchErrorMessage] = useState('');
    const [formErrors, setFormErrors] = useState(initialForm);

    // Validate email format
    const validateEmail = (email) => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
    };

    // Validate pincode format
    const validatePincode = (pincode) => {
        return /^\d{6}$/.test(pincode);
    };

   
    // Check if email is unique
    const isEmailUnique = (email) => {
        return !record.some((data) => data.email === email);
    };

    // Check if phone is unique
    const isPhoneUnique = (phone) => {
        return !record.some((data) => data.phone === phone);
    };

    // Validate form data
    const validateForm = () => {
        const errors = initialForm;

        if (!form.fname) {
            errors.fname = 'First Name is required';
        }

        if (!form.email) {
            errors.email = 'Email is required';
        } 

        if (!form.phone) {
            errors.phone = 'Phone Number is required';
        }
         

        if (!form.address) {
            errors.address = 'Address is required';
        }

        if (!form.state) {
            errors.state = 'State is required';
        }

        if (!form.pincode) {
            errors.pincode = 'Pincode is required';
        } else if (!validatePincode(form.pincode)) {
            errors.pincode = 'Pincode should be 6 digits';
        }

        if (!form.city) {
            errors.city = 'City is required';
        }

        setFormErrors(errors);
        return !Object.values(errors).some((error) => error);
    };

    // Handle pincode search
    const handleSearch = async (e) => {
        e.preventDefault();
        const pincode = form.pincode;

        if (!pincode || !validatePincode(pincode)) {
            setSearchErrorMessage('Please enter a valid 6-digit pincode.');
            return;
        }

        const url = `https://api.postalpincode.in/pincode/${pincode}`;

        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data[0].Status === 'Success') {
                const city = data[0].PostOffice[0].District;
                const state = data[0].PostOffice[0].State;
                const postOffices = data[0].PostOffice.map((office) => office.Name);
                
                setForm({
                    ...form,
                    city,
                    state,
                    selectedPostOffice: '',
                });

                setPostOfficeNames(postOffices);
                setSearchErrorMessage('');
            } else {
                setSearchErrorMessage('Pincode not found');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setSearchErrorMessage('An error occurred while fetching data.');
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!validateForm()) {
            return;
        }
    
        try {
            const response = await fetch('https://hapi.hindustanrides.in/api/v1/add_luckywinner', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    fname: form.fname,
                    email: form.email,
                    phone: form.phone,
                    address: form.address,
                    state: form.state,
                    pincode: form.pincode,
                    city: form.city,
                    selectedPostOffice: form.selectedPostOffice,
                }),
            });
            if (!response.ok) {
                throw new Error('Failed to submit form data');
            }
    
            const responseData = await response.json();
            console.log('Form submitted successfully:', responseData);
    
            
                navigate(`/scratch`, { replace: true });
            
    
            // Reset form to initial state
            setForm(initialForm);
    
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        });
    };

    return (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                
        <div className="bg-white text-gray-800 shadow-lg relative flex flex-col w-[24rem] h-[95vh] overflow-hidden">
            <div className="bg-bluecolor w-full p-4 h-full relative">
                    <div className="text-center">
                        <img
                            src={bannerImage}
                            alt="Banner Image"
                            className="w-full h-auto"
                            style={{ maxHeight: '200px' }}
                        />
                    </div>
                    <div className="p-4">
                        <form autoComplete="off">
                            <div className="relative mb-4">
                                <input
                                    type="text"
                                    className="w-full px-3 py-2 text-[#fff] rounded bg-formfiledcolor placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                                    placeholder="Full Name"
                                    name="fname"
                                    onChange={handleInputChange}
                                    value={form.fname}
                                />
                                {formErrors.fname && <p className="text-redcolor">{formErrors.fname}</p>}
                            </div>
                            <div className="relative mb-4">
                                <input
                                    type="text"
                                    className="w-full px-3 py-3 text-[#fff] rounded bg-formfiledcolor placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                                    placeholder="Email Id"
                                    name="email"
                                    onChange={handleInputChange}
                                    value={form.email}
                                />
                                {formErrors.email && <p className="text-redcolor">{formErrors.email}</p>}
                            </div>
                            <div className="relative mb-4">
                                <input
                                    type="text"
                                    className="w-full px-3 py-2 text-[#fff] rounded bg-formfiledcolor placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                                    placeholder="Mobile Number"
                                    name="phone"
                                    onChange={handleInputChange}
                                    value={form.phone}
                                    maxLength={10}
                                />
                                {formErrors.phone && <p className="text-redcolor">{formErrors.phone}</p>}
                            </div>
                            <div className="relative mb-4">
                                <input
                                    type="text"
                                    className="w-full px-3 py-2 text-[#fff] rounded bg-formfiledcolor placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                                    placeholder="Address"
                                    name="address"
                                    onChange={handleInputChange}
                                    value={form.address}
                                />
                                {formErrors.address && <p className="text-redcolor">{formErrors.address}</p>}
                            </div>
                            <div className="relative mb-4">
                                <input
                                    type="search"
                                    className="w-full px-3 py-2 text-[#fff] rounded bg-formfiledcolor placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                                    placeholder="PinCode"
                                    name="pincode"
                                    onChange={handleInputChange}
                                    value={form.pincode}
                                />
                                <button
                                    className="absolute right-2 top-2 px-4 text-black bg-graycolor font-semibold rounded hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
                                    onClick={handleSearch}
                                >
                                    Search
                                </button>
                                {formErrors.pincode && <p className="text-redcolor">{formErrors.pincode}</p>}
                                {searchErrorMessage && <p className="text-redcolor">{searchErrorMessage}</p>}
                            </div>
                          
                       
                            <div className="relative mb-4">
                                <select
                                    className="w-full px-3 py-2 text-[#fff] rounded bg-formfiledcolor placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                                    name="selectedPostOffice"
                                    onChange={handleInputChange}
                                    maxLength={6}
                                    value={form.selectedPostOffice}
                                >
                                    <option value="">Select Post Office</option>
                                    {postOfficeNames.map((name, index) => (
                                        <option key={index} value={name}>
                                            {name}
                                        </option>
                                    ))}
                                </select>
                            <p className='text-[green] text-xs'>*&nbsp;first search your area pincode and then select post office.</p>
                            </div>
                            <div className="relative mb-4">
                                <input
                                    type="text"
                                    className="w-full px-3 py-2 text-[#fff] rounded bg-formfiledcolor placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                                    placeholder="State"
                                    name="state"
                                    onChange={handleInputChange}
                                    value={form.state}
                                    disabled
                                />
                                {formErrors.state && <p className="text-redcolor">{formErrors.state}</p>}
                               
                            </div>
                            <button
                                className="w-full py-2 px-4 bg-[#fff] text-black font-semibold rounded hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormData;
