import './App.css';
import { Enrollennow } from './Componets/Enrollennow';
import FormData from './Componets/FormData';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import  Scrath  from './Componets/Scrath';
import UnLockCard from './Componets/UnLockCard';
import UserData from "./Componets/UserData"
import toast, { Toaster, ToastBar } from 'react-hot-toast';
import Data from './Componets/Data';
// import Img from './Componets/Img';



function App() {
  return (
    <BrowserRouter>
      <Toaster
        position="top-center"
        toastOptions={{
          style: {
            backgroundColor: '#fff',
            color: '#000',
            fontWeight:"bold"
          },
          // Customize the ToastBar component
          render: t => (
            <ToastBar toast={t} style={{}} position="top-center" />
          ),
        }}
        // https://luckywinnerr.netlify.app
      />
      <Routes>
        <Route path='/' element={< Enrollennow />} />
        <Route path='/formdata' element={< FormData />} />
        <Route path='/scratch' element={<Scrath/>} />
        <Route path='/unlockcard' element={<UnLockCard />} />
        <Route path='/userdata' element={<UserData />} />
        <Route path='/data' element={<Data />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
